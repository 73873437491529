.drop-down {
    background: white;
    width: 10vw;
    text-align: center;
    padding: 0.5rem 0 0.5rem 0;
    position: fixed;
    z-index: 2;
    right: 8px;
    border-radius: 0.5rem;

    .drop-down-divider {
        color: #D6D6D6;
    }

    .drop-down-item {
        list-style: none;

        button {
            width: 10vw;
            background-color: transparent;
            border: none;
            cursor: pointer;
            outline: none;
            padding: 0;
            appearance: none;
        }

        a {
            text-decoration: none;
            color: black;
        }
    }
}