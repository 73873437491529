@import './asset/css/Color.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 以下headerの設定項目 */
.header {
  width: 100vw;
  height: 64px;
  background: #F1EFE5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 41px;
  align-items: center;
  color: black;
  padding-left: 8px;
  margin: 0;
}

.logo:link, .logo:visited {
  color: black;
}

.logo:hover {
  color: gray;
}

.menu-button {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 50%;
}

.ellipsis-icon {
  position: absolute;
  right: 0;
  top: 8px;
}
