.container {
  padding: 0 80px;
}

.title {
  font-size: 40px;
  font-weight: bold;
  margin-top: 20px;
}

.detail {
  font-size: 20px;
  margin-top: 32px;
}

.icon-container {
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.shop-title {
  font-size: 30px;
  font-weight: bold;
}

.address-container {
  margin-top: 20px;
  display: flex;
}

.info-item {
  margin-left: 20px;
}

.address-item {
  display: flex;
}

.address-text {
  margin-left: 10px;
}

.youtube-icon {
  margin-left: 10px;
}

.instagram-icon {
  margin-left: 10px;
}

.tiktok-icon {
  margin-left: 10px;
}

.name-content {
  display: flex;
  margin-top: 20px;
}

.name {
  margin-left: 20px;
}

.evaluation {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:rgba(0,0,0,0.5);

  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  // transform-origin: top left;
  // transition: transform 0.1s;

  &.showPopUp {
    transform: scale(1);
  }

  .evaluation-pop-up {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    width: 50%;
    height: 25%;
    margin-top: 0.5rem;
    padding: 1rem;
    background: #fff;
    border-radius: 0.5rem;

    .evaluation-button-area {
      margin-top: 2.5rem;
      .evaluation-title {
        font-size: 2.5rem;
      }
      .evaluation-button {
        margin: 0 1rem 0 1rem;
      }
    }
  }
}