.header-text {
  text-align: center;
  padding-top: 20px;
}

.login-form {
  text-align: center;

  .email-area {
    padding-top: 20px;

    input {
      margin-top: 10px;
      width: 300px;
    }
  }

  .password-area {
    padding-top: 20px;

    input {
      margin-top: 10px;
      width: 300px;
    }
  }
}

.login-button-area {
  text-align: center;
  margin-top: 30px;

  button {
    width: 300px;
  }
}

.create-account-link {
  text-align: center;
  padding-top: 20px;
  font-weight: bold;
}