.story-list {
  .story-cell {
    width: 100%;
    height: 12.5rem;
    display: inline-block;

    .story-cell-item {
      padding-left: 5rem;

      .story-title {
        font-weight: 700;
        font-size: 30px;
        line-height: 150%;
        white-space: nowrap;
        overflow: hidden;
      }

      .story-detail {
        margin-top: 2rem;
        font-weight: 300;
        font-size: 20px;
        line-height: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
      }
    }
  }
}